.clear:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
    word-break: break-word;
}
* {
    padding: 0;
    margin: 0;
}
body,
html {
    font-family: var(--primary-light-font);
}
.page_mainBox {
    padding: 40px;
    // min-width: 1190px;
}
.pc-main{
    min-height: 100vh;
    // background-color: #000000;
    // min-width: 1440px;
    // overflow-y: auto;
    .infinite-scroll-component__outerdiv{
        width: 100%;
    }
    .noMessage-box{
        width: 100%;
        font-family: var(--primary-light-font);
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        padding: 60px 0 40px 0;
        opacity: .7;
    }
}
.mb-main{
    // background: #000000;
    min-width: 100%;
    // height: calc(var(--app-height));
    .noMessage-box{
        font-family: var(--primary-light-font);
        font-weight: 500;
        font-size: .14rem;
        color: #FFFFFF;
        line-height: .2rem;
        padding:.24rem 0 0 0;
        opacity: .7;
    }
    .centerEmptyText{
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        padding-top: 200px;
    }
    .emptyHeight{
        height: 100%;
        &::after{
            height: calc(var(--app-height) - 78px);
        }
    }
}
.baseSize{
    font-size: 16px;
}
.hidden-page{
    transition: all .5s;
    opacity: 0;
}
.show-page{
    opacity: 1;
}
.primary-color{
    color: #DCFF00;
}
.cursorPointer{
    cursor: pointer;
    user-select: none;
}
.line-box{
    width: 100%;
    height: 1px;
    background: #F5F8FD;
}
.ellipsis,.oneLineText {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}
/* custom scrollbar */
// ::-webkit-scrollbar {
//     width: 20px;
//     background-color: #000000;
//   }
  
//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
  
//   ::-webkit-scrollbar-thumb {
//     background-color: #d6dee1;
//     border-radius: 20px;
//     border: 6px solid transparent;
//     background-clip: content-box;
//   }
  
//   ::-webkit-scrollbar-thumb:hover {
//     background-color: #a8bbbf;
//   }

