/*
 * @author        r.shouwei <shouwei.ren@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.r-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    width: 100%;
    height: 60px;
    background: #FFFFFF !important;
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    font-size: 16px;
    font-family: var(--primary-light-font);
    font-weight: 400;
    color: #1C253D;
    display: flex;
    align-items: center;
    transition: all .3s;
    caret-color: #4C47DE;
    &:hover,&:focus,&:focus-within{
        border: 1px solid #D4D4D4;
        background-color: #FFFFFF;
        box-shadow: none;
    }
    &::placeholder {
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: rgba(28, 37, 61, .5) !important;
        line-height: 24px;
    }
    &:focus{
        // color: #4C47DE;
        box-shadow: none !important;
    }
    .ant-input {
        background: #FFFFFF !important;
        &::placeholder {
            font-size: 16px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: rgba(28, 37, 61, .5) !important;
        }
        font-size: 16px !important;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #1C253D;
        caret-color: #4C47DE;
    }
    &.ant-input-disabled{
        background: #FFFFFF;
        border-color: #D4D4D4;
        opacity: .3;
    }
}
.r-input_serach {
    padding-left: 10px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    svg {
        font-size: 14px;
        color: #a3a3a3;
    }
    .ant-input-suffix {
        display: flex;
        align-items: center;
        .ant-input-clear-icon {
            display: flex;
            align-items: center;
        }
    }
    .ant-input::placeholder {
        font-size: 15px;
        color: #E1E1E1 !important;
    }
    .search_icon{
        width: 24px;
        height: 24px;
        background: url('../assets/img/search.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}
.r-inputTextArea{
    height: 155px !important;
    resize: none !important;
    border: none !important;
}
.textArea-box{
    position: relative;
    width: 100%;
    height: 184px;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #D4D4D4;
    .text-count{
        position: absolute;
        bottom: 6px;
        right: 20px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        font-size: 14px;
        color: rgba(49,49,49,0.5);
        line-height: 16px;
    }
}
.r-select{
    height: 48px;
    border-radius: 10px;
    // border: 1px solid #E1E1E1;
    background-color: #FFFFFF;
    color: #222222 !important;
    .sel_arrow_icon{
        width: 12px;
        height: 12px;
        background: url('../assets/img/sel-down.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .sel_right_icon{
        width: 20px;
        height: 20px;
        background: url('../assets/img/chevron-down.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .ant-select-selector{
        width: 100%;
        border-radius: 10px;
        background: #FFFFFF !important;
        border: 1px solid #D4D4D4 !important;
        font-family: var(--primary-light-font);
        font-size: 15px;
        box-shadow: none !important;
        &:focus,&:hover,&:active{
            outline: none !important;
            border: 1px solid #D4D4D4;
            box-shadow: none !important;
            background: #FFFFFF !important;
        }
    }
    &.filter-sel{
        .ant-select-selector{
            border: 1px solid #E1E1E1 !important;
            &:focus,&:hover,&:active{
                border: 1px solid #E1E1E1;
            }
        }
    }
}
.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ff4d4f !important;
}
.r-primary_btn,
.r-primaryNoBg_btn {
    position: static;
    z-index: 3;
    height: 60px;
    background: #4C47DE;
    border-radius: 12px;
    opacity: 1;
    border: 1px solid #4C47DE;
    font-size: 20px;
    font-family: var(--primaryBtn-light-font);
    letter-spacing: -.5px;
    font-weight: normal;
    cursor: pointer;
    color: #FFFFFF;
    // box-shadow: none;
    &:hover {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
    }
}
.r-primary_btn {
    border: 1px solid #4C47DE;
    color: #FFFFFF;
    background-color: #4C47DE;
    &:disabled {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
        background-color: #4C47DE;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
        background-color: #4C47DE;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #FFFFFF !important;
        border: 1px solid #4C47DE !important;
        background-color: #4C47DE !important;
    }
}
.r-primaryNoBg_btn{
    width: 100%;
    border: 1px solid #4C47DE;
    background-color: #FFFFFF;
    color: #4C47DE;
    &:disabled {
        border: 1px solid #4C47DE;
        color: #4C47DE;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #4C47DE;
        color: #4C47DE;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #4C47DE !important;
        border: 1px solid #4C47DE !important;
        background-color: #FFFFFF !important;
    }
}
.r-primaryNoBg7F_btn{
    width: 100%;
    border: 1px solid #7F8AA9;
    background-color: #FFFFFF;
    color: #1C253D;
    &:disabled {
        border: 1px solid #7F8AA9;
        color: #1C253D;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #7F8AA9;
        color: #1C253D;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #1C253D !important;
        border: 1px solid #7F8AA9 !important;
        background-color: #FFFFFF !important;
    }
}
.r-primary_largeBtn{
    border-radius: 12px;
    height: 48px;
    font-family: var(--primary-medium-font);
    font-size: 16px;
}
.defaultBlick_btn{
    height: 48px;
}
.cancel_btn{
    color: #222222;
    background-color: #EDEDFC;
    border: 1px solid #EDEDFC;
    font-size: 16px;
    &:hover,&:focus,&:active{
        color: #222222 !important;
        background-color: #EDEDFC !important;
        border: 1px solid #EDEDFC !important;
    }
}
.danger_btn{
    color: #FFFFFF;
    background-color:#F87171;
    border: 1px solid#F87171;
    font-size: 16px;
    &:hover,&:focus,&:active{
        color: #FFFFFF !important;
        background-color:#F87171 !important;
        border: 1px solid#F87171 !important;
    }
}
.r-datePicker,.r-downTimePick {
    height: 48px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0 16px;
    border: 1px solid #E1E1E1;
    &:hover,&:focus,&:focus-within{
        border: 1px solid #E1E1E1;
        background-color: #FFFFFF;
        box-shadow: none;
    }
    .sel_time_icon {
        width: 20px;
        height: 20px;
        background: url('../assets/img/date.png') no-repeat;
        background-size: contain;
    }
    &::before {
        content: '';
        min-width: 21px;
        height: 21px;
        background: url('../assets/img/date.png') no-repeat;
        background-size: contain;
        margin: 0 13px 0 0;
    }
    .ant-picker-clear {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
        svg {
            font-size: 14px;
            color: #a3a3a3;
        }
    }
    .ant-picker-input>input {
        text-align: left;
        font-size: 15px;
        font-family: var(--primary-light-font);
        color: #222222;
        &::placeholder {
            font-size: 15px;
            font-family: var(--primary-light-font);
            font-weight: normal;
            color: #E1E1E1 !important;
            // color: #d6d6d6 !important;
            opacity: 1;
        }
    }
    .ant-picker-range-separator {
        // display: none;
        opacity: 0;
        display: flex;
        align-items: center;
        position: relative;
        .ant-picker-separator {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 5px;
            height: 2px;
            background: #E1E1E1;
        }
    }
    .ant-picker-active-bar {
        display: none;
        margin-inline-start: 30px !important;
    }
}
.r-downTimePick{
    width: 100%;
    &::before{
        display: none;
    }
    .calendar-check{
        width: 20px;
        height: 20px;
        background: url('../assets/img/calendar-check.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}
.r-date_showLine {
    .ant-picker-range-separator {
        opacity: 1;
    }
}
.loading_icon{
    display: inline-block;
    width: 42px;
    height: 42px;
    background: url('../assets/img/loading.png') no-repeat;
    background-size: contain;
}
.tip-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/tip.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.editR-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/edit-round.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.delR-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/Delete-round.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.more_icon{
    display: block;
    width: 30px;
    height: 30px;
    background: url('../assets/img/opeation.png') no-repeat;
    background-size: contain;
    cursor: pointer;
    transition: all .2s;
    &:hover{
        background: url('../assets/img/opeationed.png') no-repeat;
        background-size: contain;
    }
}
.more_iconSeled{
    display: block;
    width: 30px;
    height: 30px;
    background: url('../assets/img/opeationed.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.radio_icon{
    display: block;
    width: 12px;
    height: 12px;
    background: url('../assets/img/radio.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.radioed_icon{
    display: block;
    width: 12px;
    height: 12px;
    background: url('../assets/img/radioed.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.r-radioText{
    font-family: Metropolis-Regular, Metropolis;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
}
.check_icon{
    display: block;
    width: 16px;
    height: 16px;
    background: url('../assets/img/Checkbox.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.checked_icon{
    display: block;
    width: 16px;
    height: 16px;
    background: url('../assets/img/Checkboxed.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
@keyframes rotate {
    0% { transform: rotate(0deg); } 
    100% { transform: rotate(360deg); } 
}
.ant-spin .ant-spin-dot{
    animation-name: rotate; 
    animation-duration: .8s;
    animation-iteration-count: infinite; 
    animation-timing-function: linear; 
}
.r-divider{
    color: rgba(255, 255, 255, .7) !important;
    margin: 24px 0 4px 0 !important;
    &::after,&::before{
        border-block-start-color: rgba(255, 255, 255, .7) !important;
    }
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.emptyTetx{
    color: #FFFFFF;
    font-size: 14px;
    padding: 40px 0;
}
.mart20{
    margin-top: 20px;
}
.mart15{
    margin-top: 15px;
}
.home-nextBtn.disabled{
    opacity: .5;
}
.fullLoading-box{
    div.ant-spin{
        position: fixed;
        max-height: initial;
        z-index: 20;
    }
    div>.ant-spin .ant-spin-dot{
        margin: -20px;
    }
    .ant-spin-blur{
        opacity: 1;
        &::after{
            background-color: #000000;
            opacity: .7;
        }
    }
}
.r-modal {
    width: 530px;
    margin: 20px auto;
    .ant-modal-close{
        width: 48px;
        height: 48px;
        top: 30px;
        right: 30px;
        background-color: #FFFFFF;
        &:hover{
            background-color: #FFFFFF;
        }
    }
    .r-modal-close{
        width: 48px;
        height: 48px;
        background-image: url('../assets/img/Remove-black.png');
        background-size: contain;
    }
    .modal-top-title{
        font-family: Metropolis-Medium, Metropolis;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        text-align: left;
        padding: 12px 0 37px 0;
        border-bottom: 1px solid #EDEDED;
    }
    .ant-modal-header,.ant-modal-footer{
        margin: 0;
    }
    .ant-modal-content {
        border-radius: 18px !important;
        padding:30px !important;
        box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        .mini-tip{
            font-family: OpenSans-Regular, var(--primary-light-font);
            font-weight: 400;
            font-size: 16px;
            color: #4D4D4D;
            line-height: 24px;
            text-align: left;
        }
        .form-tip{
            font-family: Poppins-Medium, Roboto;
            font-weight: 500;
            font-size: 16px;
            color: #757575;
            line-height: 19px;
            text-align: left;
            margin-bottom: 12px;
        }
    }
    .r-primary_btn{
        width: 100%;
    }
    .r-cancel_btn{
        margin-right: 20px;
    }
}
.tailor-modal{
    .ant-modal-content{
        padding: 24px !important;
    }
}
.r-modal-video{
    height: 450px;
    .r-modal-close{
        width: 20px;
        height: 20px;
        background-image: url('../assets/img/Remove-white.png');
        background-size: contain;
        top: 0px;
    }
    .ant-modal-close{
        top: -44px;
        right: -5px;
    }
    .ant-modal-content{
        // background-color: rgba(30, 34, 45, 0.8),
        padding: 0;
        background-color: #494c55;
        box-shadow: none;
        height: 450px;
    }
}
.sub-info-modal{
    .ant-modal-close{
        top: 38px;
        right: 26px;
    }
    .ant-modal-content{
        padding: 40px 35px 30px 35px !important;
    }
    .modal-top-title{
        text-align: left;
    }
}
.mini-tip{
    font-size: 12px;
}
.login-form{
    .ant-form-item .ant-form-item-label >label{
        font-family: Poppins-Medium, Roboto;
        font-weight: 500;
        font-size: 16px !important;
        color: #6C6C6C !important;
        line-height: 19px;
    }
}
.planInfo-form{
    .ant-form-item .ant-form-item-label >label{
        font-family: Poppins-Medium, Roboto;
        font-weight: 500;
        font-size: 16px !important;
        color: #757575 !important;
        line-height: 19px;
    }
    .ant-form-item {
        margin-bottom: 25px;
    }
}
.ant-form-item .ant-form-item-label >label {
    font-family: var(--primary-light-font);
    font-weight: 400;
    font-size: 12px !important;
    color: #1C253D !important;
    line-height: 15px;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.ant-form-item .ant-form-item-explain-error{
    text-align: left;
    font-family: var(--primary-light-font);
    font-size: 14px;
}
.ant-form-item{
    margin-bottom: 30px;
}
.r-table{
    .ant-table-thead{
        .ant-table-cell{
            font-family: var(--primary-semiBold-font);
            font-weight: 500;
            font-size: 14px;
            color: #222222;
            line-height: 16px;
            border-bottom:none;
            background-color: #EDEDFC;
            padding: 22px 20px !important; 
            &::before{
                display: none;
            }
            &:first-child,&:last-child{
                border-radius: 0 !important;
            }
        }
    }
    .ant-table-tbody{
        .r-tableRow{
            height: 64px;
            .ant-table-cell{
                word-break: break-all;
                font-family: var(--primary-light-font);
                font-weight: 400;
                font-size: 16px;
                color: #222222;
                line-height: 19px;
                min-height: 64px !important;
                padding: 14px 20px !important;
                border-bottom: 1px solid #F6F5FF;
                .cell-txt{
                    word-break: break-word;
                }
            }
        }
    }
}
.r-table_mini{
    .ant-table-thead{
        .ant-table-cell{
            font-family: Roboto-Medium;
            font-weight: 500;
            font-size: 15px;
            color: #797979;
            line-height: 16px;
            border-bottom:1px solid #DFDEFB;
            background-color: #FFFFFF;
            padding: 15px 10px !important; 
            &::before{
                display: none;
            }
            &:first-child,&:last-child{
                border-radius: 0 !important;
            }
        }
    }
    .ant-table-tbody{
        .r-tableRow-mini{
            .ant-table-cell{
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                padding: 10px !important;
                border-bottom: none;
                .img-icon{
                    width: 32px;
                    height: 32px;
                    background: #9490F1;
                    border-radius: 100px 100px 100px 100px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.ant-popover-inner{
    padding: 10px 20px !important;
}
.tableOpeation-box{
    .tb_item{
        font-family: var(--primary-medium-font);
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 19px;
        text-align: left;
        padding: 10px 0;
        cursor: pointer;
        user-select: none;
        &.danger{
            color: #F87171;
        }
        &.success{
            color: #3ECC92;
        }
    }
}

.mb-main{
    .r-modal {
        width: 3.42rem !important;
        .ant-modal-content {
            font-size: 16px;
            border-radius: 12px !important;
            padding:38px .16rem .16rem !important;
            box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        }
        .r-cancel_btn{
            margin-right: .14rem;
        }
        .ant-modal-close{
            top: 6px;
            right: 6px;
        }
        .r-cancel_btn,.r-primary_btn{
            height: 40px;
            font-size: .14rem;
            font-family: Inter-Medium;
        }
    }
}
.r-switch{
    &.ant-switch-checked:nth-child(1) {
        background-color: #eaeaea;
        background-color: #5b9d4f !important;
       
    }
    &.ant-switch{
        background-color: #eaeaea !important;
    }
}

  
