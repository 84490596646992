@font-face {
    font-family: Poppins-SemiBold;
    src: url(./Poppins/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: Poppins-Medium;
    src: url(./Poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: OpenSans-Regular;
    src: url(./OpenSans/OpenSans-Regular.ttf);
}
@font-face {
    font-family: OpenSans-Bold;
    src: url(./OpenSans/OpenSans-Bold.ttf);
}
@font-face {
    font-family: Roboto-Regular;
    src: url(./Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto-Medium;
    src: url(./Roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: Roboto-SemiBold;
    src: url(./Roboto/Roboto-Bold.ttf);
}
@font-face {
    font-family: Metropolis-Regular;
    src: url(./Metropolis/Metropolis-Regular.otf);
}
@font-face {
    font-family: Metropolis-Medium;
    src: url(./Metropolis/Metropolis-Medium.otf);
}
@font-face {
    font-family: Metropolis-SemiBold;
    src: url(./Metropolis/Metropolis-SemiBold.otf);
}