// video
.video-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
  
  video {
    width: 100%;
    height: 450px;
    background-color: #191919;
    border-radius: 12px;
  }
  
  .custom-play-button {
    position: absolute;
    width: 94px;
    height: 94px;
    background: url('../../assets/img/play.png') no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    // display: none; /* Initially hidden */
  }
  
  .video-container.paused .custom-play-button {
    display: block;
  }
  
// alert modal
.primary-modal-tip{
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 28px;
    text-align: center;
}
.cancel_btn{
    color: #222222;
    background-color: #EDEDFC;
    border: 1px solid #EDEDFC;
    font-size: 16px;
    &:hover,&:focus,&:active{
        color: #222222 !important;
        background-color: #EDEDFC !important;
        border: 1px solid #EDEDFC !important;
    }
}
.danger_btn{
    color: #FFFFFF;
    background-color:#F87171;
    border: 1px solid#F87171;
    font-size: 16px;
    &:hover,&:focus,&:active{
        color: #FFFFFF !important;
        background-color:#F87171 !important;
        border: 1px solid#F87171 !important;
    }
}
.sub-info-modal{
    .sub-boldTip{
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 20px;
        color: #1C253D;
        line-height: 23px;
        text-align: left;
        margin: 8px 0 20px 0;
    }
    .noMar{
        margin: 0;
    }
}