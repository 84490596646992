.c-pageContent{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .pad-top{
        margin: 30px 0 38px 0;
    }
    // 
    h2{
        font-family: Roboto-SemiBold;
        font-weight: 600;
        font-size: 32px;
        color: #1C253D;
        line-height: 40px;
        text-align: left;
        margin: 40px 0 20px 0;
    }
    .detailInfo-tip{
        font-weight: 400;
        font-size: 16px;
        color: #222222;
        line-height: 19px;
        text-align: left;
    }
    .detailTable-box{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #E7E7E7;
        padding: 0 16px 16px 16px;
        margin-top: 20px;
    }
}