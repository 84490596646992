.c-pageContent-feedback{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .pad-top{
        margin: 30px 0 38px 0;
    }
    .cell-text-underline{
        text-decoration: underline;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        font-family: Metropolis-Medium;
    }
    .red-roundBox{
        width: 10px;
        min-width: 10px;
        height: 10px;
        background: #F87171;
        border-radius: 50%;
        position: absolute;
        left: -15px;
    }
}
.feed-modal{
    .name{
        margin-left: 10px;
        font-family: Metropolis-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 19px;
        text-align: left;
    }
    .maodl-blackText{
        font-family: Metropolis-Regular, Metropolis;
        font-weight: 400;
        font-size: 14px;
        color: #7A7A7A;
        line-height: 28px;
        text-align: left;
    }
    .op-color{
        color: #7B7B7B;
    }
    .mart5{
        margin-top: 5px;
    }
}