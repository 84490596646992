.c-pageContent-subscription{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .jobNobg-btn{
        height: 48px !important;
        width: 190px;
        border-radius: 6px !important; 
        font-size: 15px !important;
        font-family: Metropolis-Regular !important;
        &.marr20{
            margin-right: 20px;
        }
        &:disabled{
            opacity: .3;
        }
    }
    .sub-topBox{
        margin: 40px 0 60px 0;
        align-items: stretch;
        .rate-box{
            flex: 1;
            min-width: 650px;
            // height: 100%;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #DFDEFB;
            padding: 20px;
        }
        .total-box{
            margin-left: 30px;
            width: 364px;
            min-width: 364px;
            height: 100%;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #DFDEFB;
            padding: 20px;
        }
        .rate-item{
            flex: 1;
            text-align: center;
        }
        .subBox-tit{
            font-family: Metropolis-SemiBold, Metropolis;
            font-weight: normal;
            font-size: 20px;
            color: #222222;
            line-height: 23px;
            text-align: left;
        }
        .price-box{
            font-family: Roboto-SemiBold, Roboto;
            font-weight: 600;
            font-size: 32px;
            color: #1C253D;
            line-height: 40px;
            text-align: center;
        }
        .price-box-total{
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #1C253D;
            line-height: 25px;
            text-align: center;
        }
        .price-tip{
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 20px;
            color: #1C253D;
            line-height: 25px;
            margin: 30px 0 10px 0;
        }
    }
    .sub-plan{
        .plan-tit{
            font-family: Metropolis-SemiBold, Metropolis;
            font-weight: normal;
            font-size: 32px;
            color: #222222;
            line-height: 38px;
            text-align: left;
            padding-bottom: 20px;
            border-bottom: 1px solid #EDEDED;
            margin-bottom: 20px;
        }
        .plan-btn{
            width: 115px;
            height: 37px;
            background: #4C47DE;
            border-radius: 8px 8px 8px 8px;
            cursor: pointer;
            user-select: none;
            font-family: Roboto-SemiBold, Roboto;
            font-weight: 600;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 37px;
            text-align: center;
            margin:0 0 20px 0;
        }
        .planList-box{
            flex-wrap: wrap;
            .plan-item{
                width: calc((100% - 40px) / 3);
                height: 155px;
                border-radius: 12px;
                border: 1px solid #DFDEFB;
                padding: 20px;
                margin-bottom: 20px;
                &:nth-child(3n-1){
                    margin: 0 20px 20px 20px;
                }
                .plan-name{
                    font-family: Roboto-SemiBold, Roboto;
                    font-weight: 600;
                    font-size: 20px;
                    color: #1C253D;
                    line-height: 25px;
                    text-align: left;
                }
                .plan-teamNum{
                    font-family: Roboto-Regular, Roboto;
                    font-weight: 400;
                    font-size: 14px;
                    color: #1C253D;
                    line-height: 18px;
                    text-align: left;
                    margin-top: 8px;
                }
                .plan-price{
                    text-align: right;
                    width: 145px;
                    min-width: max-content;
                    font-family: Roboto-SemiBold, Roboto;
                    font-weight: 600;
                    font-size: 24px;
                    color: #1C253D;
                    line-height: 30px;
                }
                .plan-line{
                    width: 100%;
                    height: 1px;
                    background: #EDEDED;
                    margin: 20px 0;
                }
            }
        }
        .plan-opIcon{
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.marl30{
                margin-left: 30px;  
            }
        }
    }
    // record
    h2{
        font-family: Roboto-SemiBold;
        font-weight: 600;
        font-size: 32px;
        color: #1C253D;
        line-height: 40px;
        text-align: left;
        margin: 40px 0 30px 0;
    }
    .detailTable-box{
        margin-top: 38px;
    }
}
.rateModalLabel-box{
    font-family: Metropolis-SemiBold, Metropolis;
    font-weight: normal;
    font-size: 32px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    height: 60px;
}
.label-w{
    width: 100px;
    margin-right: 30px;
}
.rateModalPrice{
    font-family: Metropolis-Regular, Metropolis;
    font-weight: 400;
    font-size: 32px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    user-select: none;
}
.price-input{
    font-weight: 400;
    font-size: 16px;
    color: #313131;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    &:disabled{
        opacity: .3;
    }
}
.disabled{
    opacity: .5;
    cursor: no-drop !important;
    .r-radioText,.check_icon{
        cursor: no-drop;
    }
}