.c-pageContent{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .pad-top{
        margin: 30px 0 38px 0;
    }
    .profile-info-pri{
        font-weight: 400;
        font-size: 12px;
        color: #1C253D;
        line-height: 15px;
        text-align: left;
        font-family: Roboto-Regular;
    }
    .p-urlTit{
        font-family: Roboto-SemiBold;
        font-weight: 600;
        font-size: 16px;
        color: #1C253D;
        line-height: 20px;
        margin-bottom: 5px;
    }
    // detail
    .userInfo-box{
        width: 100%;
        // height: 356px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #E7E7E7;
        margin-top: 44px;
        padding: 16px;
        .userName-box{
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 22px;
            color: #1C253D;
            line-height: 28px;
            text-align: left;
            margin-bottom: 4px;
        }
        .user-job{
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 18px;
            text-align: left;
        }
        .userInfo-msgBox{
            padding: 16px 0 ;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 16px;
            text-align: left;
            border-bottom: 1px solid #ECECEC;
            .userInfo-pIcon{
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }
            .flex1{
                min-width: 250px;
                margin-right: 20px;
            }
            .mart12{
                margin-top: 12px;
            }
        }
        .userDesc-box{
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            margin-top: 16px;
            white-space: pre-wrap;
        }
    }
    .userVideo-list{
        padding: 20px 0 0 0;
        .videoBox-item{
            .video-tip{
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                font-size: 16px;
                color: #1C253D;
                line-height: 19px;
                text-align: left;
                margin-bottom: 12px;
            }
            .video-img{
                position: relative;
                width: 280px;
                height: 326px;
                background: rgba(28,37,61,0.7);
                border-radius: 12px 12px 12px 12px;
            }
            .play-imgIcon{
                position: absolute;
                width: 72px;
                height: 72px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                cursor: pointer;
            }
            &:nth-child(2){
                margin: 0 20px;
            }
        }
    }
    .relevantExperience-box{
        padding: 24px 16px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #E7E7E7;
        padding-bottom: 5px;
        .relevantExperience-tip{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 18px;
            color: #4C47DE;
            line-height: 21px;
            text-align: left;
            margin-bottom: 20px;
            .black-color{
                color: #1C253D;
            }
        }
        .relevantExperienceList{
            flex-wrap: wrap;
            .relevantExperience-item{
                width: 272px;
                height: 102px;
                background: #F0F4F9;
                border-radius: 8px 8px 8px 8px;
                padding: 12px;
                margin-right: 16px;
                &.greenBg{
                    background: #E2FAF1;
                }
                .experie-tit{
                    font-family: Roboto-SemiBold, Roboto;
                    font-weight: 600;
                    font-size: 18px;
                    color: #1C253D;
                    line-height: 25px;
                }
                .jobCheck-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                }
                .experie-job{
                    font-size: 14px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                    margin: 12px 0 5px 0;
                }
                .experie-jobTime{
                    opacity: 0.5;
                    font-size: 12px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                }
            }
        }
    }
    .portfolio-box{
        width: 100%;
        height: auto;
        background: #EEEEEE;
        border-radius: 12px 12px 12px 12px;
        margin: 20px 0;
        padding: 24px 16px 6px 16px;
        .port-tit{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 18px;
            color: #292929;
            line-height: 21px;
            text-align: left;
        }
        .portList-box{
            padding: 20px 30px 0 30px;
            flex-wrap: wrap;
            .portImg-box{
                cursor: pointer;
                min-width: 282px;
                width: calc((100% - 60px) / 4);
                height: 210px;
                border-radius: 12px 12px 12px 12px;
                background-color: #ccc;
                background-position: center center;
                margin-right: 20px;
                margin-bottom: 20px;
                &:nth-child(4n){
                    margin-right: 0;
                }
            }
        }
    }
    .licenses-box{
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #E7E7E7;
        .r-top{
            margin-top: 10px;
            &:first-child{
                margin: 0;
            }
        }
        .file-icon{
            width: 32px;
            height: 32px;
            background: url('../../../../assets/img/licens.png') no-repeat;
            background-size: contain;
            margin-right: 8px;
        }
        .file-name{
            font-size: 14px;
            font-family: Roboto-Medium;
            color: #1C253D;
        }
        .file-time{
            margin-top: 8px;
            font-size: 12px;
            font-family: Roboto-Regular;
            color: #1C253D;
        }
        .link-icon{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/img/link.png') no-repeat;
            background-size: contain;
            cursor: pointer;
        }
    }
}