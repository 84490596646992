:root {
    --primary-semiBold-font: 'Metropolis-SemiBold';
    --primary-medium-font: 'Metropolis-Medium';
    --primary-light-font: 'Metropolis-Regular';
    --primaryBtn-light-font: 'Poppins-SemiBold';
    --primary-color: '#DCFF00';
    --primary-pending-color: '#FFA800';
    --primary-success-color: '#82C74F';
    --app-height:'100%';
}
