.c-pageContent-codes{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .pad-top{
        margin: 30px 0 38px 0;
        .add-codeBtn{
            width: 195px;
            height: 48px;
            background: #4C47DE;
            border-radius: 8px 8px 8px 8px;
            font-family: Roboto-SemiBold;
            font-size: 15px;
            color: #FFFFFF;
            cursor: pointer;
            user-select: none;
        }
    }
    .cell-text-underline{
        text-decoration: underline;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        font-family: Metropolis-Medium;
    }
    .cell-text-danger{
        font-family: Metropolis-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #F87171;
        line-height: 16px;
        text-align: left;
        margin-left: 40px;
        cursor: pointer;
        user-select: none;
    }
}
.feed-modal{
    .name{
        margin-left: 10px;
        font-family: Metropolis-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 19px;
        text-align: left;
    }
    .maodl-blackText{
        font-family: Metropolis-Regular, Metropolis;
        font-weight: 400;
        font-size: 14px;
        color: #7A7A7A;
        line-height: 28px;
        text-align: left;
    }
    .op-color{
        color: #7B7B7B;
    }
    .mart5{
        margin-top: 5px;
    }
    .code-showlist{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        .codes-item{
            margin-right: 70px;
            margin-bottom: 10px;
            &:nth-child(3n){
                margin-right: 0;
            }
            .copy-icon{
                width: 18px;
                height: 18px;
                background: url('../../../assets/img/copy.png') no-repeat;
                background-size: contain;
                cursor: pointer;
            }
        }
    }
}
.codes-info-form{
    .mart20{
        margin-top: 20px;
    }
}