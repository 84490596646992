.layout-container {
    height: 100%;
    background: #4C47DE;
    position: relative;
    // height: calc(var(--app-height));
    min-height: 100vh;
    min-width:1366px;
    padding-top: 72px;
    .header_nav {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        width: 100%;
        height: 72px;
        background: #4C47DE;
        padding: 0 50px;
        .header-logo{
            height: 38px;
            margin-right: 5%;
        }
        .navItem{
            font-family: var(--primary-light-font);
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            height: 52px;
            line-height: 52px;
            text-align: center;
            // padding: 16px 30px;
            user-select: none;
            cursor: pointer;
            border-radius: 30px 30px 30px 30px;
            min-width: max-content;
            transition: all .2s;
            position: relative;
            margin-right: 7px;
            justify-content: center;
            &.active,&:hover{
                font-family: Metropolis-SemiBold;
                background: rgba(255,255,255,0.2);
                // padding: 16px 30px;
            }
            .red-roundBox{
                position: absolute;
                left: 10px;
                top: 21px;
                width: 10px;
                height: 10px;
                background: #F87171;
                border-radius: 50%;
            }
            &:nth-child(1),&:nth-child(3){
                width: 135px;
            }
            &:nth-child(3){
                width: 100px;
            }
            &:nth-child(2){
                width: 158px;
            }
            &:nth-child(4){
                width: 132px;
            }
            &:nth-child(5){
                width: 170px;
            }
            &:nth-child(6){
                width: 145px;
            }
        }
        .off-icon{
            width: 48px;
            height: 48px;
            min-width: 48px;
            background: url('../assets/img/off.png') no-repeat;
            background-size: contain;
            cursor: pointer;
        }
        .admin-icon{
            width: 48px;
            min-width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #FFFFFF;
            font-family: Metropolis-Medium, Metropolis;
            font-weight: 500;
            font-size: 24px;
            color: #4C47DE;
            margin-left: 22px;
        }
    }
    .layout-content{
        background: #FFFFFF;
        height: calc(100vh - 72px);
        border-radius: 18px 18px 0 0;
        padding: 44px 80px 60px 80px;
        overflow-y: auto;
        // &::-webkit-scrollbar {
        //     width: 10px;
        // }
    
        // &::-webkit-scrollbar-track {
        //     background-color: #f1f1f1;
        //     border-radius: 0 0 0 0;
        // }
    
        // &::-webkit-scrollbar-thumb {
        //     background-color: #a8a8a8;
        //     border-radius: 6px;
        // }
    }
}
