.c-pageContent-jobRole{
    h1{
        font-family: Metropolis-SemiBold, Metropolis;
        font-weight: normal;
        font-size: 36px;
        color: #222222;
        line-height: 42px;
        text-align: left;
    }
    .set-item{
        width: 100%;
        height: 70px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #DFDEFB;
        padding: 0 20px;
        font-family: Metropolis-SemiBold;
        color: #222222;
        font-size: 22px;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;
        .un-applications-box{
            color: #F87171;
            font-size: 16px;
            font-family: Metropolis-Medium;
            margin-left: auto;
        }
    }
    .pad-top{
        margin: 30px 0 38px 0;
    }
    .top-opBtnBox{
        .roleRightNum-box{
            position: relative;
            .roleNum-box{
                position: absolute;
                right: 7px;
                top: -14px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 28px;
                padding: 0 10px;
                height: 28px;
                background: #F87171;
                border-radius: 30px 30px 30px 30px;
                font-size: 15px;
                font-style: normal;
                color: #FFFFFF;
                font-family: Metropolis-Regular;
            }
        }
    }
    .jobNobg-btn{
        height: 48px !important;
        width: 170px;
        border-radius: 6px !important; 
        font-size: 15px !important;
        font-family: Metropolis-Regular !important;
        &.marr20{
            margin-right: 20px;
        }
    }
    .jobbg-btn{
        height: 48px !important;
        width: 170px;
        border-radius: 6px !important; 
        font-size: 15px !important;
        font-family: Metropolis-SemiBold !important;
    }
    .click-row{
        text-decoration: underline;
        color: #2346E3;
        cursor: pointer;
        user-select: none;
    }
    // detail
    h2{
        font-family: Metropolis-SemiBold;
        font-weight: 600;
        font-size: 32px;
        color: #1C253D;
        line-height: 40px;
        text-align: left;
        margin: 40px 0 30px 0;
    }
    .cell-minBtn{
        width: 93px;
        height: 35px;
        background: #EDEDFC;
        border-radius: 30px 30px 30px 30px;
        font-family: Metropolis-Medium, Metropolis;
        font-weight: 500;
        font-size: 15px;
        color: #222222;
        cursor: pointer;
        user-select: none;
    }
    .cell-minBtn-danger{
        width: 93px;
        height: 35px;
        background: #FFFFFF;
        border-radius: 30px 30px 30px 30px;
        font-family: Metropolis-Medium, Metropolis;
        font-weight: 500;
        font-size: 15px;
        color: #F87171;
        border: 1px solid #F87171;
        cursor: pointer;
        user-select: none;
        margin-right: 10px;
    }
    .detailTable-box{
        width: 100%;
        height: auto;
        background: #FFFFFF;
        margin-top: 30px;
    }
}
// accept modal
.addSkill-btn{
    width: 71px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #4C47DE;
    font-family: Metropolis-Regular;
    color: #4C47DE;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    margin-left: 10px;
    &:disabled{
        background: #FFFFFF !important;
        border: 1px solid #4C47DE !important;
        color: #4C47DE !important;
        opacity: 0.4;
    }
}

// tag list
.tag-listBox{
    flex-wrap: wrap;
    .tag-itemBox{
        line-height: 32px;
        text-align: center;
        padding: 0 15px;
        width: max-content;
        height: 32px;
        background: #EAE9FC;
        border-radius: 8px 8px 8px 8px;
        font-weight: 400;
        font-size: 12px;
        color: #1C253D;
        margin-right: 8px;
        margin-bottom: 8px;
        .tag-colseIcon{
            width: 12px;
            height: 12px;
            background: url('../../../../assets/img/tagclose.png') no-repeat;
            background-size: contain;
            cursor: pointer;
            margin-left: 8px;
        }
    }
}