.back-box{
    cursor: pointer;
    user-select: none;
    width: 73px;
    height: 24px;
    font-family: Metropolis-Medium, Metropolis;
    font-weight: 500;
    font-size: 15px;
    color: #909090;
    line-height: 18px;
    text-align: left;
    .back-icon{
        width: 24px;
        min-width: 24px;
        height: 24px;
        background: url('../../assets/img/back.png') no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-right: 10px;
    }
}