.portFolioDrawer-box{
    .ant-drawer-content{
        border-radius: 32px 32px 0 0 ;
    }
    .ant-drawer-content-wrapper{
        .ant-drawer-body{
            position: relative;
            padding: 70px 15%;
            .close-icon-drawer{
                position: fixed;
                z-index: 100;
                right: 20px;
                top: 4%;
                width: 24px;
                height: 24px;
                background: url('../../assets/img/Remove-drawer.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                transition: all .3s;
            }
            .portFolio-tit{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 26px;
                margin-left: 12px;
            }
            .marb24{
                margin-bottom: 24px;
            }
            .imgBox{
                width: 100%;
                // height: 588px;
                background-color:#ccc;
            }
            .textBox{
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                font-size: 24px;
                color: #1C253D;
                line-height: 32px;
                text-align: left;
            }
            .link-icon-draw{
                width: 22px;
                height: 22px;
                // background-color: #4C47DE;
                background: url('../../assets/img/Link@2x.png') no-repeat;
                background-size: contain;
                margin-right: 10px;
            }
            .footerLink{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 16px;
                color: #4C47DE;
                line-height: 22px;
                text-align: left;
                text-decoration: underline;
            }
        }
    }
    .ant-drawer-mask{
        background-color: rgba(30, 34, 45, 0.8);
        backdrop-filter: blur(1px);
    }
}