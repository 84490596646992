.login-main{
    min-height: 100vh;
    background: #EFEFEF;
    padding: 40px 0;
    .center-box{
        padding: 50px 40px;
        text-align: center;
        width: 550px;
        background: #FFFFFF;
        border-radius: 18px 18px 18px 18px;
        box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
    }
    .logo-img{
        width: 272px;
        margin-bottom: 40px;
    }
    .login-formBox{
        background: #FFFFFF;
        h2{
            font-family: OpenSans-Bold;
            font-weight: bold;
            font-size: 32px;
            color: #091936;
            line-height: 40px;
            text-align: left;
        }
        .tit-tip{
            font-family: OpenSans-Regular;
            font-weight: 400;
            font-size: 16px;
            color: #091936;
            line-height: 24px;
            text-align: left;
            margin: 12px 0 40px 0;
        }
        .form-tip{
            font-family: Poppins-Medium, Roboto;
            font-weight: 500;
            font-size: 16px;
            color: #6C6C6C;
            line-height: 19px;
            text-align: left;
            margin-bottom: 12px;
        }
        .pp-miniTip{
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            font-size: 14px;
            color: #ACACAC;
            line-height: 16px;
            text-align: left;
            margin-top: 12px;
        }
        .eye-open{
            width: 24px;
            height: 24px;
            background-image: url('../../assets/img/eye-open.png');
            background-size: contain;
            cursor: pointer;
        }
        .eye-close{
            width: 24px;
            height: 24px;
            background-image: url('../../assets/img/eye-closed.png');
            background-size: contain;
            cursor: pointer;
        }
        .forgetBox{
            text-align: right;
            font-family: Poppins-SemiBold;
            font-weight: 500;
            font-size: 16px;
            color: #4C47DE;
            line-height: 19px;
            text-decoration: underline;
            margin: 20px 0 40px 0;
            &>span{
                cursor: pointer;
            }
        }
        .r-primary_btn{
            width: 100%;
        }
        .btm-tip{
            margin-top: 15px;
            font-size: 12px;
            color: #1C253D;
            &>.forgetBox{
                font-size: 12px;
            }
        }
    }
    .success_img{
        width: 148px;
    }
    .succ_tit{
        font-family: Metropolis-Medium, Metropolis;
        font-weight: 500;
        font-size: 24px;
        color: #151515;
        line-height: 28px;
        text-align: center;
        margin: 85px 0 40px 0;
    }
}

.mb-main{
}